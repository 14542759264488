.save-input label {
  font-weight: bold;
}

.input-btn {
  margin-top: 5px;
  display: flex;
  gap: 9px;
}

.save-input input {
  border-radius: 6px;
  border: 3px solid;
  color: white;
  outline: none;
  padding: 0 50px 0 10px;
  background-color: transparent;
}

.save-input button {
  border: 1px solid;
  border-radius: 8px;
  padding: 0 10px;
  background-color: white;
  cursor: pointer;
}

@media (max-width: 655px) {
  .input-btn {
    justify-content: center;
  }
  .form-control {
    width: 286px;
    margin: auto;
  }
  .form-control label {
    text-align: left;
  }
}
