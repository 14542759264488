.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.75);
}

.modal {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  top: 20vh;
  left: 15%;
  width: 70%;
  min-width: 26rem;
  padding: 1rem;
  border-radius: 14px;
  box-shadow: 1px 3px 14px rgb(138, 97, 233);
  z-index: 30;
  animation: slide-down 500ms ease-out forwards;
  text-align: center;
  height: 20rem;
}

.modal > div {
  margin-bottom: 2.5rem;
}

@media (max-width: 870px) {
  .modal {
    height: auto;
  }
}

@media (max-width: 590px) {
  .modal {
    top: 15vh;
    left: 10%;
    min-width: auto;
    width: 80%;
  }
}

@media (max-width: 450px) {
  .modal {
    left: 5%;
    width: 90%;
  }
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-15rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
