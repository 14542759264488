.palettes {
  margin: auto;
  max-width: 1000px;
}

.head {
  margin: 4rem 0;
}

.head h1 {
  text-align: center;
  margin-bottom: 2rem;
  border-bottom: 2px rgb(179, 176, 176) solid;
}

.saved-palettes {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5px;
  margin: auto;
}

.instructions {
  margin-top: 15px;
}

.instructions div {
  display: flex;
  gap: 10px;
  align-items: center;
  line-height: 35px;
}

.instructions p i {
  font-size: 15px;
}

.example-add-button {
  border: 1px solid;
  border-radius: 8px;
  padding: 0 7px;
  background-color: white;
}

.instructions .circle {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  border: 1px solid rgb(255, 255, 255);
}

.instructions .back-start {
  margin-top: 2rem;
  border-top: 2px rgb(179, 176, 176) solid;
  justify-content: center;
}

.circle-empty {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+5,b5b5b5+6,b5b5b5+6,b5b5b5+11,1c1c1c+13,1c1c1c+17,b5b5b5+18,b5b5b5+27,2b2b2b+27,2b2b2b+35,b5b5b5+36,b5b5b5+36,b5b5b5+44,000000+46,000000+55,b5b5b5+57,b5b5b5+57,b5b5b5+66,1c1c1c+68,1c1c1c+72,1c1c1c+77,b5b5b5+79,b5b5b5+86,1c1c1c+88,1c1c1c+88,1c1c1c+90,1c1c1c+91,1c1c1c+94,b5b5b5+96,b5b5b5+100 */
  background: #000000; /* Old browsers */
  background: -moz-linear-gradient(
    -45deg,
    #000000 0%,
    #000000 5%,
    #b5b5b5 6%,
    #b5b5b5 6%,
    #b5b5b5 11%,
    #1c1c1c 13%,
    #1c1c1c 17%,
    #b5b5b5 18%,
    #b5b5b5 27%,
    #2b2b2b 27%,
    #2b2b2b 35%,
    #b5b5b5 36%,
    #b5b5b5 36%,
    #b5b5b5 44%,
    #000000 46%,
    #000000 55%,
    #b5b5b5 57%,
    #b5b5b5 57%,
    #b5b5b5 66%,
    #1c1c1c 68%,
    #1c1c1c 72%,
    #1c1c1c 77%,
    #b5b5b5 79%,
    #b5b5b5 86%,
    #1c1c1c 88%,
    #1c1c1c 88%,
    #1c1c1c 90%,
    #1c1c1c 91%,
    #1c1c1c 94%,
    #b5b5b5 96%,
    #b5b5b5 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    -45deg,
    #000000 0%,
    #000000 5%,
    #b5b5b5 6%,
    #b5b5b5 6%,
    #b5b5b5 11%,
    #1c1c1c 13%,
    #1c1c1c 17%,
    #b5b5b5 18%,
    #b5b5b5 27%,
    #2b2b2b 27%,
    #2b2b2b 35%,
    #b5b5b5 36%,
    #b5b5b5 36%,
    #b5b5b5 44%,
    #000000 46%,
    #000000 55%,
    #b5b5b5 57%,
    #b5b5b5 57%,
    #b5b5b5 66%,
    #1c1c1c 68%,
    #1c1c1c 72%,
    #1c1c1c 77%,
    #b5b5b5 79%,
    #b5b5b5 86%,
    #1c1c1c 88%,
    #1c1c1c 88%,
    #1c1c1c 90%,
    #1c1c1c 91%,
    #1c1c1c 94%,
    #b5b5b5 96%,
    #b5b5b5 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    135deg,
    #000000 0%,
    #000000 5%,
    #b5b5b5 6%,
    #b5b5b5 6%,
    #b5b5b5 11%,
    #1c1c1c 13%,
    #1c1c1c 17%,
    #b5b5b5 18%,
    #b5b5b5 27%,
    #2b2b2b 27%,
    #2b2b2b 35%,
    #b5b5b5 36%,
    #b5b5b5 36%,
    #b5b5b5 44%,
    #000000 46%,
    #000000 55%,
    #b5b5b5 57%,
    #b5b5b5 57%,
    #b5b5b5 66%,
    #1c1c1c 68%,
    #1c1c1c 72%,
    #1c1c1c 77%,
    #b5b5b5 79%,
    #b5b5b5 86%,
    #1c1c1c 88%,
    #1c1c1c 88%,
    #1c1c1c 90%,
    #1c1c1c 91%,
    #1c1c1c 94%,
    #b5b5b5 96%,
    #b5b5b5 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#b5b5b5',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

@media (max-width: 834px) {
  .instructions div {
    flex-wrap: wrap;
  }
}

@media (max-width: 686px) {
  .head h1 {
    font-size: 18px;
  }
}

@media (max-width: 581px) {
  .instructions div {
    gap: 0;
  }
  .instructions div div {
    gap: 10px;
  }
}

@media (max-width: 484px) {
  .back-start h3 {
    font-size: 15px;
  }
  .instructions div {
    justify-content: center;
  }
  .instructions div i,
  .instructions .circle,
  .instructions button,
  .instructions div > svg,
  .instructions > div > div {
    margin-bottom: 2rem;
  }
}

@media (max-width: 400px) {
  .back-start h3 {
    text-align: center;
  }
}
