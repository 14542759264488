@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  font-family: 'Montserrat', sans-serif;
  list-style: none;
}

body {
  background-color: rgb(44, 44, 44);
  color: rgb(236, 236, 236);
}

.slide-picker {
  gap: 20px;
  flex-direction: column;
  display: flex;
  height: 100px;
  justify-content: center;
}
