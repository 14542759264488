.header {
  height: auto;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  font-size: 25px;
}

@media (max-width: 655px) {
  .header {
    font-size: 20px;
  }
}

@media (max-width: 630px) {
  .header {
    justify-content: left;
    margin-left: 3rem;
    font-size: 20px;
  }
}

@media (max-width: 580px) {
  .header {
    justify-content: left;
    margin-left: 1rem;
  }
}

@media (max-width: 551px) {
  .header {
    font-size: 17px;
  }
  .header svg {
    width: 40px;
  }
}

@media (max-width: 465px) {
  .header {
    font-size: 15px;
  }
  .header svg {
    width: 35px;
  }
}

@media (max-width: 425px) {
  .header {
    font-size: 13px;
    gap: 20px;
  }
  .header svg {
    width: 30px;
  }
}

@media (max-width: 371px) {
  .header {
    font-size: 12px;
    gap: 10px;
  }
  .header svg {
    width: 28px;
  }
}

@media (max-width: 336px) {
  .header {
    margin-left: .8rem;
    font-size: 12px;
    gap: 10px;
  }
  .header svg {
    width: 27px;
  }
}
