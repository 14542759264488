.circles-section {
  border-radius: 10px;
  padding: 0.4rem 0.5rem;
  display: flex;
  justify-content: space-between;
  background-color: #151515;
}

.circle {
  width: 22px;
  height: 22px;
  border-radius: 11px;
  border: 1px solid rgb(255, 255, 255);
}

@media (max-width: 585px) {
  .circles-section {
    gap: 0;
  }
}