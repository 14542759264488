.palettes {
  margin: auto;
  max-width: 1000px;
}

.head {
  margin: 5rem 0 2rem 1.9rem;
}

.saved-palettes {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5px;
  justify-items: center;
}

@media (max-width: 1000px) {
  .saved-palettes {
    grid-template-columns: 1fr 1fr;
  }
  .head {
    margin-left: 3.9rem;
  }
}

@media (max-width: 767px) {
  .head {
    margin-left: 1.9rem;
  }
}

@media (max-width: 632px) {
  .head {
    margin-top: 2.5rem;
    margin-bottom: .8rem;
    margin-left: 4rem;
  }
  .saved-palettes {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 411px) {
  .head {
    margin-left: 1.9rem;
  }
}

@media (max-width: 345px) {
  .head {
    margin-left: 1.1rem;
  }
}
