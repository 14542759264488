.drop-down-card {
  padding: 0.5rem;
  max-width: 170px;
  max-height: 40px;
  border-radius: 12px;
  box-shadow: 1px 2px 5px rgb(138, 97, 233);
}

.drop-down-card > div > div {
  justify-content: center;
  font-size: 15px;
  padding: 0.1rem 1rem;
}

@media (max-width: 834px) {
  .drop-down-card {
    padding: 0;
  }
}
